import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "../../../helpers/Functions";

export const dashboardSlice = createSlice({
	name: "dashboard",
	initialState: {
		categories: [],
		user: [],
		companies: [],
		team: [],
		position: [],
		roles: [],
		libraries: {
			selected_library: [],
			active_page: []
		},
		ai: {
			type: "",
			response: "",
			string: "",
			prompt: "",
			settings: "",
			prompt_params : ""
		},
		languages: ""
	},
	reducers: {
		setCategories: (state, action) => {
			state.categories = action.payload;
		},
		setCompanies: (state, action) => {
			state.companies = action.payload;
		},
		setCompanyUser: (state, action) => {
			state.users = action.payload;
		},
		setTeams: (state, action) => {
			state.team = action.payload;
		},
		setPositions: (state, action) => {
			state.position = action.payload;
		},
		setRoles: (state, action) => {
			state.roles = action.payload;
		},
		setSelectedLibrary: (state, action) => {
			state.libraries.selected_library = action.payload;
		},
		setLibraryTitle: (state, action) => {
			let libraryIndex = state.libraries.selected_library.findIndex((lib) => lib.library_code == action.payload.code)
			state.libraries.selected_library[libraryIndex].title = action.payload.title;
			state.libraries.selected_library[libraryIndex].category = {
				party_id : action.payload.categories.party_id,
				name : action.payload.categories.name,
				sub_categories : (!isEmpty(action.payload.sub_categories)) ? {
					party_id: action.payload.sub_categories.party_id,
					name: action.payload.sub_categories.name
				} : {}
			};
		},
		setAI: (state, action) => {
			state.ai = action.payload;
		},
		setLanguages: (state, action) => {
			state.languages = action.payload
		}
	}
});

export const {
	setCategories,
	setCompanyUser,
	setCompanies,
	setTeams,
	setPositions,
	setSelectedLibrary,
	setLibraryTitle,
	setAI,
	setLanguages,
	setRoles
} = dashboardSlice.actions;

export default dashboardSlice.reducer;