import CircularProgress from '@mui/material/CircularProgress';
import { isEmpty } from '../../helpers/Functions';
import { Tooltip } from '@mui/material';

const ActionIconButton = ({ ...props }) => {
	const handleClick = (e) => {
        if (props.disabled || props.issubmitting) {
            e.preventDefault();
            e.stopPropagation();
        }
		else {
			props.onClick(e);
		}
    };

	return (
		<div className={`color-primary fit-content pointer ${props.customclasses}`} 
			{...props}
			onClick={handleClick}
		>
			{
				(props.issubmitting && typeof props.disabled == 'undefined') ? (
					<CircularProgress
						className="me-2"
						sx={{
							color: "primary"
						}}
						size={20}
						thickness={7}
					/>
				):(
					(!isEmpty(props.icontooltip)) ? (
						<Tooltip title={props.icontooltip} placement='top'>
							{props.icon}
						</Tooltip>
					) 
					: (
						props.icon
					)
					
				)
			}
			<span className={`ms-2 font-weight-medium color-primary pointer ${props.labelclasses}`} {...props.labelprops}>{props.label}</span>
		</div>
	)
}

export default ActionIconButton;