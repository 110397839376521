import Image from 'react-bootstrap/Image';

const Logo = ({ ...props }) => {
	return (
		<div className="theme-logo" {...props}>
			<Image
				style={{ objectFit: "contain" }}
				className="w-100 h-100"
				src="/lernnex-logo.png"
				alt="Lernnex Logo"
			/>
		</div>
	)
}

export default Logo;