import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import dashboardReducer from "./slices/dashboardSlice";
import authReducer from "./slices/authSlice";
import adminPanelReducer from "./slices/adminPanelSlice";
import quickAccessReducer from "./slices/quickAccessSlice";
import appReducer from "./slices/appSlice";

const persistDashboardReducer = persistReducer({
		key: "dashboard",
		storage,
		whitelist:["categories", "libraries", "users", "team", "position", "ai", "languages"]
	},
	dashboardReducer
);

const persistAuthReducer = persistReducer({
		key: "auth",
		storage,
		whitelist: ["token", "user_details", "user_capabilities", "current_capabilities", "is_super_admin"]
	},
	authReducer
);

const persistAdminPanel = persistReducer({
	key: "adminPanel",
	storage,
	whitelist: ["users", "companies","categories","teams","positions","roles","prompts", "convo_starters"]
},
	adminPanelReducer
);

const persistQuickAccess = persistReducer({
	key: "quickAccess",
	storage,
	whitelist: ["ai", "email", "element", "name", "agreed"]
},
	quickAccessReducer
);

const persistApp = persistReducer({
	key: "app",
	storage,
	whitelist: ["app_version"]
},
	appReducer
);

const reducers = combineReducers({
	dashboard: persistDashboardReducer,
	auth: persistAuthReducer,
	adminPanel: persistAdminPanel,
	quickAccess: persistQuickAccess,
	app: persistApp
});

export const store = configureStore({
	reducer: reducers,
	middleware: [thunk],
	devTools: process.env.REACT_APP_ENV !== "production",
});

export const persistor = persistStore(store);