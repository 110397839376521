import React from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button
} from '@mui/material';

const YesNoDialog = ({ ...props }) => {
	return (
		<Dialog open={props.open}>
			<DialogTitle>Confirmation</DialogTitle>
			<DialogContent style={{ whiteSpace: 'pre-line' }}>
				{props.message}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						props.onClose?.(false)
						props.onNo?.()
					}}
					color="primary"
				>
					No
				</Button>
				<Button 
					onClick={() => {
						props.confirm?.(props.item)
						props.onClose?.(false)
					}} 
					color="primary"
				>
					Yes
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default YesNoDialog;