import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import { InputLabel, Modal, Typography ,Box, IconButton, Paper, SvgIcon, CircularProgress, Rating} from '@mui/material';
import { QUICK_ACCESS_BG, QUICK_ACCESS_LOGO, DEFAULT_OLERN_TRANSPARENT_LOGO, DEFAULT_APPROVE_LOGO } from '../models/Constants';
import { isEmpty } from '../helpers/Functions';
import Divider from '../theme/partials/Divider';
import { Image } from 'react-bootstrap';
import StarIcon from '@mui/icons-material/Star';
import TextView from '../theme/partials/Typography';

const ReviewPage = ({ ...props }) => {
	const [isFullWidth, setIsFullWidth] = useState(false);
	const [paperStyle, setPaperStyle] = useState({})
	const [ratingValue, setRatingValue] = useState(null)
	const [hover, setHover] = useState(-1)

	const labels = [
		'',
		'1 Star',
		'2 Star',
		'3 Star',
		'4 Star',
		'5 Star',
	];

	const useQuery = () => {
		return new URLSearchParams(useLocation().search);
	};

	const query = useQuery();

	useEffect(() => {
		const checkScreenWidth = () => {
		  if (window.innerWidth >= 1200) {
			setIsFullWidth(true);
			setPaperStyle({ px: 3, pt: 3, pb: 5, borderRadius: 5, width : "80vw", height: "70vh" });
		  }
		  if (window.innerWidth <= 430) {
			setIsFullWidth(true);
			setPaperStyle({ px: 3, pt: 3, pb: 5, borderRadius: 5, width : "80vw", height: "60vh" });
		  } 
		  else {
			setIsFullWidth(false);
			setPaperStyle({ px: 2, pt: 2, pb: 3, borderRadius: 5, width : "70vw", height: "60vh" });
		  }
		};

		checkScreenWidth();

		window.addEventListener('resize', checkScreenWidth);
		return () => window.removeEventListener('resize', checkScreenWidth);
	  }, []);

	const getLabelText = (value) => {
		return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
	}


	useEffect(() => {
		if(!isEmpty(ratingValue)) {
			if(ratingValue < 5){
				setTimeout(function() {
					window.location.href = "https://www.olern.com/details-of-feedback/";
				}, 1000)
			}
			else {
				setTimeout(function() {
					window.location.href = "https://www.olern.com/gift-of-feedback/";
				}, 1000)
			}
		}
	}, [ratingValue])
	

	return (<>
		<ToastContainer />
		<div className="qa-wide-container p-3 w-100"
			style={{
				backgroundImage: "url(" + QUICK_ACCESS_BG + ")", 
				backgroundSize: 'cover', 
				backgroundPosition: 'center', 
				backgroundRepeat: 'no-repeat',
			}}
		>
			<div className='px-1 w-100 d-block'>
				<div className='d-flex w-100 justify-content-center align-items-center'>
					<Image
						className={`d-flex m-2 mb-3`}
						style={{
							backgroundColor: 'transparent',
							width: "20vh",
							objectFit: 'contain'
						}}
						src={DEFAULT_OLERN_TRANSPARENT_LOGO}
						alt="Olern Logo"
					/>
				</div>
				<div className='d-flex justify-content-center align-items-center'>
					<Paper 
						elevation={3}
						sx={paperStyle}
						className='my-5'
					>
						
						<div className='d-flex m-2 mb-3 justify-content-center align-items-center'>
							<Image
								className="responsive-qa-logo"
								src={DEFAULT_APPROVE_LOGO}
								alt="Olern Logo"
							/>
						</div>
						
						<center>
							<TextView className={`font-weight-medium ${(window.innerWidth <= 430 ? "font-size-large" : "font-size-xxl")} m-1 mb-5`}>Based on your recent experience with us, how would you rate Olern?</TextView>
							<TextView className={`font-weight-medium ${(window.innerWidth <= 430 ? "font-size-medium" : "font-size-xl")} m-1 color-quinary`}>Your feedback means a lot to us.</TextView>
							<div
								className="mb-2"
							>
								<Rating
									sx={{
									fontSize: {
										xs: '3rem',
										sm: '4rem',
										md: '5rem',
										lg: '6rem',
									},
									}}
									name="hover-feedback"
									value={ratingValue}
									getLabelText={getLabelText}
									onChange={(event, newValue) => {
										setRatingValue(newValue);
									}}
									onChangeActive={(event, newHover) => {
										setHover(newHover);
									}}
									emptyIcon={<StarIcon style={{ opacity: 0.55, fontSize : (window.innerWidth <= 430) ? "2rem" : "5rem" }} />}
								/>
								<Box 
									sx={{ ml: 2 }}
									className="font-size-large"
								>
									{hover !== -1 ? labels[hover !== -1 ? hover : ratingValue] : 
									isEmpty(ratingValue) ? "Please select a rating above to start" : labels[ratingValue]}
								</Box>
							</div>
						</center>
					</Paper>
				</div>
				
				<div className='d-flex flex-column mt-4 w-100 overflow-x-auto'>
					<div className='mt-4 mb-3 font-weight-black'>
						<center>
							Get FREE <a href={process.env.REACT_APP_WP_URL} target='_blank'>Trainings for SMEs!</a>
						</center>
					</div>
				</div>
			</div>
		</div>
	</>);
};

export default ReviewPage;