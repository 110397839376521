import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
	name: "app",
	initialState: {
		app_version: ""
	},
	reducers: {
		setAppVersion: (state, action) => {
			state.app_version = action.payload;
		},
	}
});

export const {
	setAppVersion
} = appSlice.actions;

export default appSlice.reducer;