import { createSlice } from "@reduxjs/toolkit";

const quickAccessSlice = createSlice({
	name: "quickAccess",
	initialState: {
		email: "",
		name: {
			firstname: "",
			lastname: ""
		},
		agreed: false,
        ai: {
			type: "",
			response: "",
			string: "",
			prompt: "",
			settings: "",
			prompt_params : ""
		},
		element: [],
	},
	reducers:{
		setAI: (state, action) => {
			state.ai = action.payload;
		},
		setEmail: (state, action) => {
			state.email = action.payload;
		},
		setElement: (state, action) => {
			state.element = action.payload;
		},
		setName: (state, action) => {
			state.name = action.payload;
		},
		setAgreed: (state, action) => {
			state.agreed = action.payload;
		}
	}
});

export const {
	setAI,
	setEmail,
	setElement,
	setName,
	setAgreed
} = quickAccessSlice.actions;

export default quickAccessSlice.reducer;