/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {forwardRef} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { setCurrentCapabilities } from "../../../app/redux/slices/authSlice";
import BaseButton from "../../partials/BaseButton";
import TextView from "../../partials/Typography";
import { SUPER_PERMISSIONS } from "../../../models/Constants";
import { isEmpty } from "../../../helpers/Functions";

const QuickActionsDropdownToggle = forwardRef((props, ref) => {
	
	return (
		<a
			ref={ref}
			href="#"
			onClick={e => {
				e.preventDefault();
				props.onClick(e);
			}}
			id="kt_subheader_quick_actions"
			className="btn btn-sm btn-clean"
		>
			<span className="ms-3 me-2">View as: ({!isEmpty(props.currentCapabilities?.role_name) ? props.currentCapabilities?.role_name : "Role"})</span>
			<span className="svg-icon svg-icon-primary svg-icon-lg">
				<span className="svg-icon-primary svg-icon-2x">
					<PersonSearchIcon className="color-primary" />
				</span>
			</span>
		</a>
	);
});

const RoleSelection = ({ currentCapabilities, setCurrentCapabilities, isSuperAdmin }) => {
	const navigate = useNavigate();

	const setCurrentRole = (role, name, isLimited = 0) => {
		let updatedCapabilities = {...currentCapabilities};
		updatedCapabilities.role = role;
		updatedCapabilities.role_name = name;
		updatedCapabilities.is_limited = isLimited;

		setCurrentCapabilities(updatedCapabilities);

		if(role == 4) {
			navigate("/admin-panel");
		}
		else {
			navigate("/dashboard");
		}
	}

	return (
		<OverlayTrigger
			style={{ display: "inline", }}
			placement="left"
			overlay={<Tooltip id="quick-actions-tooltip">Change Your Role</Tooltip>}
		>
			<Dropdown
				className="dropdown-inline d-inline float-end"
				style={{ lineHeight: "40px", padding: "6px" }}
				drop="down"
			>
				<Dropdown.Toggle
					as={QuickActionsDropdownToggle}
					id="dropdown-toggle-quick-actions-subheader"
					currentCapabilities={currentCapabilities}
				/>

				{currentCapabilities?.company?.roles?.length >= 1 && (
					<Dropdown.Menu
						className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right"
						style={{ minWidth: "100px" }}
					>
						<TextView className="px-3">Change your role:</TextView>
						<hr className="m-0" />
						<div className="p-3">
							{
								currentCapabilities.company.roles.map((role, index) => { 
									return (
										<BaseButton
											key={index}
											customclasses="mb-2 w-100"
											variant="small"
											onClick={() => { setCurrentRole(role.role_id, role.role_name, role.is_limited) }}
										>
											{role.role_name}
										</BaseButton>
									)
								})
							}

							{
								isSuperAdmin === 1 && (
									<BaseButton
										customclasses="mb-2 w-100"
										variant="small"
										onClick={() => { setCurrentRole(4, "Super Admin") }}
									>
										Super Admin
									</BaseButton>
								)
							}
						</div>
					</Dropdown.Menu>
				)}
			</Dropdown>
		</OverlayTrigger>
	);
}

const mapStateToProps = (state) => {
	let data = {
		userCapabilities: state.auth.user_capabilities,
		currentCapabilities: state.auth.current_capabilities,
		isSuperAdmin: state.auth.is_super_admin
	}

	return data;
}

const mapDispatchToProps = {
	setCurrentCapabilities
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleSelection);