import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const companiesSlice = createSlice({
	name: "adminPanel",
	initialState: {
		users:[],
		companies:[],
		teams:[],
		positions:[],
		categories:[],
		prompts:[],
		convo_starters: []
	},
	reducers:{
		setCompaniesData: (state, action) => {
			state.companies = action.payload;
		},
		setCompanyUser: (state, action) => {
			state.users = action.payload;
		},
		setCompanyTeam: (state, action) => {
			state.teams = action.payload;
		},
		setCompanyPosition: (state, action) => {
			state.positions = action.payload;
		},
		setAdminCategories: (state, action) => {
			state.categories = action.payload;
		},
		setPrompts: (state, action) => {
			state.prompts = action.payload;
		},
		setConvoStarters: (state, action) => {
			state.convo_starters = action.payload;
		}
	}
});

export const {
	setCompaniesData,
	setCompanyUser,
	setAdminCategories,
	setCompanyTeam,
	setCompanyPosition,
	setPrompts,
	setConvoStarters
} = companiesSlice.actions;

export default companiesSlice.reducer;