import AsyncSelect from 'react-select/async';
import { components, DropdownIndicatorProps } from 'react-select';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FormHelperText from '@mui/material/FormHelperText';

import TextView from "./Typography";
import { InputLabel } from '@mui/material';

const DropdownIndicator = ( props: DropdownIndicatorProps ) => {
	return (
		<components.DropdownIndicator {...props}>
			<ArrowDropDownIcon style={{ color: "#777" }} />
		</components.DropdownIndicator>
	);
};

export const SearchDropdown = ({display = 'block', ...props}) => {
	return (
		<div className={`w-100 mb-3 d-${display} ${props.customclasses}`}>
			{props.title && (
				<InputLabel
					className={`
						${(props.titleclass) ? props.titleclass :
						"font-size-medium color-septenary"}
						mb-2`
					}
				>
					{props.title}
				</InputLabel>
			)}
			<AsyncSelect
				styles={{
					valueContainer: (base) => ({
						...base,
						padding: "0.5rem",
						fontSize: "var(--font-size-primary)"
					})
				}}
				components={{ DropdownIndicator }}
				{...props}
			/>
		</div>
	)
}

export const FooterMenuItem = ({...props}) => {

	return (
		<MenuItem style={{ padding: "0 2rem", fontWeight: "600" }}>
			{ props?.title ? "+ " + props.title : "+ Add Item" }
		</MenuItem>
	)
}

const DropDown = ({...props}) => {
	let smallStyle = { height: "40px" };

	return (
		<FormControl
			classes={{root: props.formclasses}}
			{...props}
		>
			{
				props.title && (
					<TextView
						display="block"
						customclasses="mb-1 color-septenary font-size-medium"
					>
						{ props.title }
					</TextView>
				)
			}

			<Select
				style={props.small ? smallStyle : {}}
				value={props?.value}
				className={`${props.itemclass}`} 
				displayEmpty
				inputProps={{ 'aria-label': 'Without label' }}
				onChange={props.onChange}
				MenuProps={{ style: { zIndex: 1502 } }}
			>
				{
					props?.defaultoption && (
						<MenuItem key={props?.defaultoption.key} value={props?.defaultoption.key}>
							{
								props.checkbox ? (
									<div>
										<Checkbox className="d-inline-block" checked={false} />
										<ListItemText className="d-inline-block" primary={props?.defaultoption.name} />
									</div>
								)
								: (
									<em>{ props?.defaultoption.name }</em>
								)
							}
						</MenuItem>
					)
				}
				{
					props?.options?.map((value) => {
						return (
							<MenuItem key={value.key} value={ value.key }>
								{
									props.checkbox ? (
										<div>
											<Checkbox className="d-inline-block" checked={false} />
											<ListItemText className="d-inline-block" primary={value.name} />
										</div>
									)
									: (
										value.name
									)
								}
							</MenuItem>
						)
					})
				}
				{
					props?.footer && (
						<div>
							<hr/>
							{ props.footer }
						</div>
					)
				}
			</Select>

			{
				Boolean(props.error) && (
					<FormHelperText
						style={{
							fontSize: "var(--font-size-primary)",
							color: "var(--theme-red)",
						}}
					>
						{props.helperText}
					</FormHelperText>
				)
			}
		</FormControl>
	)
}

export default DropDown;