import React, { useRef, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import SplashScreen from "./SplashScreen";
// import * as auth from "./authRedux";
import { getUserByToken } from "./crud/person.crud";
import { getCategories } from "./crud/category.crud";
import { setUserCapabilities, setCurrentCapabilities, setSuperAdmin } from "./redux/slices/authSlice";
import { setCategories, setLanguages } from "./redux/slices/dashboardSlice";
import { isEmpty } from "../helpers/Functions";
import displayToast from "../theme/partials/DisplayToast";
import { initializeUserData } from "./LoginInit";
import { useNavigate } from "react-router-dom";
import { setAppVersion } from "./redux/slices/appSlice";

const AuthInit = ({
	setUserCapabilities,
	setCurrentCapabilities,
	currentCapabilities,
	isSuperAdmin,
	setSuperAdmin,
	setCategories,
	authToken,
	setLanguages,
	appVersion,
	setAppVersion,
	...props
}) => {
	const didRequest = useRef(false);
	const dispatch = useDispatch();
	const [showSplashScreen, setShowSplashScreen] = useState(true);
	const navigate = useNavigate();

	// We should request user by authToken before rendering the application
	useEffect(() => {
		const requestUser = async () => {
			try {
				if(!didRequest.current) {
					initializeUserData(
						setUserCapabilities,
						setCurrentCapabilities,
						setSuperAdmin,
						currentCapabilities,
						setLanguages,
						undefined,
						false,
						appVersion,
						setAppVersion,
						navigate
					);
				}
			}
			catch (error) {
				if (!didRequest.current) {
					// todo: dispatch(props.logout()); ???
				}
			}
			finally {
				setShowSplashScreen(false);
			}

			return () => (didRequest.current = true);
		};

		if(authToken) {
			requestUser();
		}
		else {
			// todo: dispatch(props.fulfillUser(undefined)); ???
			setShowSplashScreen(false);
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if(!isEmpty(currentCapabilities?.company?.company_id)) {
			getCategories(currentCapabilities.company.company_id).then((response) => {
				if(response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					if(response.data.init[0].recno == 0) {
						setCategories([]);
					}
					else {
						setCategories(response.data.data);
					}
				}
			});
		}
	}, [currentCapabilities?.company?.company_id]);

	return showSplashScreen ? <SplashScreen /> : <>{props.children}</>;
}

const mapStateToProps = (state) => {
	let data = {
		appVersion: state.app.app_version,
		currentCapabilities: state.auth.current_capabilities,
		isSuperAdmin: state.auth.is_super_admin,
		authToken: state.auth.token
	}

	return data;
}

const mapDispatchToProps = {
	setUserCapabilities,
	setCurrentCapabilities,
	setSuperAdmin,
	setCategories,
	setLanguages,
	setAppVersion
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthInit);
