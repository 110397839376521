import axios from "axios";
import { DEFAULT_AUTH_HEADER } from "../../models/Constants";
import { objectToUrlQuery } from "../../helpers/Functions";

export function getLanguages() {
	return axios.get(process.env.REACT_APP_API_URL + "/master-data/languages");
}

export function getQuickLanguages() {
	return axios.get(process.env.REACT_APP_API_URL + "/d/master-data/languages" , DEFAULT_AUTH_HEADER);
}

export function getAIPrompts(params) {
	return axios.get(process.env.REACT_APP_API_URL + "/master-data/prompts?" + objectToUrlQuery(params));
}

export function getQuickIdentifications() {
	return axios.get(process.env.REACT_APP_API_URL + "/d/master-data/identifications" , DEFAULT_AUTH_HEADER);
}