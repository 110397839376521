import { Iron } from "@mui/icons-material";

export function getCurrentUrl(location) {
	return location.pathname.split(/[?#]/)[0];
}

export function checkIsActive(location, url) {
	const current = getCurrentUrl(location);
	const parts = current.split("/");
	const subRoute = parts[2];

	if(url === subRoute) {
		return true
	}

	if(!current || !url) {
		return  false;
	}

	if(current === url) {
		return  true;
	}

	return false;
}