import "../assets/theme.css";
import { BrowserRouter } from "react-router-dom";
import { Provider, connect } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import axios from "axios";
import { AppRoutes } from "./AppRoutes";
import Layout from "../theme/layout/Layout";
import AuthInit from "./AuthInit";

function App({ store, persistor }) {
	let location = window.location;

	axios.interceptors.request.use(
		(config) => {
			if(!config.headers.Authorization) {
				const state = store.getState();

				if(state.auth.token) {
					config.headers.Authorization = `Bearer ${state.auth.token}`;
				}
			}

			return config;
		},
		(err) => Promise.reject(err)
	);

	return (
		<BrowserRouter>
			<Provider store={ store }>
				<PersistGate loading={ null } persistor={ persistor }>
					<AuthInit>
						<AppRoutes Layout={ Layout } />
					</AuthInit>
				</PersistGate>
			</Provider>
		</BrowserRouter>
	);
}

export default App;