import axios from "axios";
import { objectToUrlQuery } from "../../helpers/Functions";

export function getCategories(companyID, params = 0) {
	const param = (params == 0) ? "" : objectToUrlQuery(params)
	return axios.get(process.env.REACT_APP_API_URL + "/category/get-categories/" + companyID + "?" + param);
}

export function updateCompanyCategories(params,company_id){
	return axios.post(process.env.REACT_APP_API_URL + "/category/update-company-category/" + company_id, params);
}

export function getAdminCategories(){
	return axios.get(process.env.REACT_APP_API_URL + "/category/get-admin-categories");
}

export function updateAdminCategories(params){
	return axios.post(process.env.REACT_APP_API_URL + "/category/update-admin-category", params);
}