import React, { useEffect, useState, useRef } from "react";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import TextView from "../Typography";
import BaseButton from "../BaseButton";
import Divider from "../Divider";
import { Col, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { signOut } from "../../../app/redux/slices/authSlice";
import { isEmpty } from "../../../helpers/Functions";
import { DIR_USER_PROFILE } from "../../../models/Constants";

const AvatarDropDown = ({ ...props }) => {
	const [avatar, setAvatar] = useState(null);
	let iconStyle = { fontSize: "1.5rem" };
	const navigate = useNavigate();

	useEffect(() => {
		if(typeof props?.userdetail.firstname !== "undefined") {
			let initials = props?.userdetail.firstname.slice(0, 1);

			setAvatar(initials.toUpperCase());
			props.setUpdate(true);
		}
	}, []);

	const handleSignOut = async () => {
		await new Promise((resolve) => {
			signOut();
			localStorage.removeItem('persist:auth');  
			resolve();
		});

		navigate('/login');
		window.location.reload(); 
	};

	return (
		<div
			className="content-container mt-2 bg-denary"
			style={{
				position: "absolute",
				display: "inline-block",
				top: "100%",
				right: "70%",
				zIndex: 9999,
				width: "28rem",
				overflow: "hidden",
			}}
		>
			<div className="container">
				<div
					className="row d-flex align-items-center px-3 bg-tertiary"
					style={{ height: "5rem" }}
				>
					<Col xs={2} className="theme-header-profile-picture">
						<div className="theme-header-initials">
							{
								(!isEmpty(props.userdetail.user_img)) ?  
								<Image 
									className="d-block rounded border"
									src={process.env.REACT_APP_S3_BUCKET + DIR_USER_PROFILE + props.userdetail.user_img}
									style={{
										objectFit: "cover",
										height: 40,
										width: 40,
									}}
								/> : 
								avatar
							}
						</div>
					</Col>
					<Col xs={10} className="d-flex justify-content-start px-3">
						<TextView className="font-size-large text-white">
							{props.userdetail.firstname}
						</TextView>
					</Col>
				</div>
			</div>

			<a
				style={{ cursor: "pointer" }}
				onClick={(e) => {
					e.preventDefault();
					navigate("dashboard/user-profile");
				}}
			>
				<div
					className="row d-flex align-items-center"
					style={{ height: "5rem" }}
				>
					<Col
						xs={2}
						className="d-flex justify-content-center align-items-start"
					>
						<AccountBoxIcon style={iconStyle} className="color-primary"/>
					</Col>
					<Col xs={10} className="d-flex flex-column align-items-start">
						<TextView
							className="font-size-medium text-black"
							style={{ lineHeight: "1.2" }}
						>
							My Profile
						</TextView>
						<TextView
							className="font-size-secondary color-secondary"
							style={{ lineHeight: "1.2" }}
						>
							Account Setting and more
						</TextView>
					</Col>
				</div>
			</a>

			<Divider />

			<div
				className="row d-flex align-items-center justify-content-start px-3"
				style={{ height: "5rem" }}
			>
				<div className="d-flex ">
					<BaseButton type="submit" onClick={handleSignOut}>Sign Out</BaseButton>
				</div>
			</div>
		</div>
	);
};

export default AvatarDropDown;