import React from "react";
import Image from 'react-bootstrap/Image';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

const SplashScreenContainer = styled('div')(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100vw",
	height: "100vh",
	backgroundColor: "#ffffff",
}));

const SplashScreen = () => {
	return (
		<SplashScreenContainer>
			<CircularProgress className="d-block"/>
			<Image
				style={{
					objectFit: "contain",
					width: "150px",
					height: "auto",
				}}
				src="/lernnex-logo.png"
				alt="Lernnex Logo"
			/>
		</SplashScreenContainer>
	)
}

export default SplashScreen;